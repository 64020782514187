import React from 'react'

import styled from 'styled-components'

import Header from '../../../components/header/header'
import InfoContact from '../../../components/infoContact/infoContact'

import imgLocation from '../../../images/contact/location.svg'
import imgCity from '../../../images/contact/city.svg'
import imgEmail from '../../../images/contact/email.svg'
import imgClock from '../../../images/contact/clock.svg'
import imgPhone from '../../../images/contact/phone.svg'

const List = styled.ul`
  margin-top: 35px;

  @media (min-width: 960px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
`

const DataSection = props => {
  const data = [
    { img: imgLocation, desc: props.data.adres },
    { img: imgCity, desc: props.data.code },
    { img: imgEmail, desc: props.data.email },
    { img: imgPhone, desc: props.data.phone },
    { img: imgClock, desc: props.data.pnSat },
    { img: imgClock, desc: props.data.sun },
  ]

  return (
    <>
      {/* <Header as={'h2'} firstLetter>
        OŚRODEK SZKOLENIA KIEROWCÓW SZÓSTKA 
    </Header> */}
      <List>
        {data.map(el => (
          <InfoContact img={el.img}>{el.desc}</InfoContact>
        ))}
      </List>
    </>
  )
}

export default DataSection
