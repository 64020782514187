import styled from 'styled-components'

const Button = styled.button`
  padding: 5px 40px;
  background-color: ${({ theme }) => theme.colorYellow};
  /* background-color: ${({ theme }) => theme.colorBlack}; */
  color: ${({ theme }) => theme.colorBlack};
  /* color: ${({ theme }) => theme.colorYellow}; */
  font-weight: bold;
  letter-spacing: 2px;
  /* text-transform: capitalize; */
  text-transform: uppercase;
  border-radius: 5px;
`

export default Button
