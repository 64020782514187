import * as Yup from 'yup'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import Link from '../../../components/link/link'

import { useFormik } from 'formik'
import styled from 'styled-components'

import Header from '../../../components/header/header'
import Desc from '../../../components/desc/desc'
import Button from '../../../components/button/button'

const data = [
  { id: 'fullname', type: 'text', content: 'imię i nazwisko' },
  { id: 'email', type: 'email', content: 'adres email' },
  { id: 'message', type: 'text', as: 'textarea', content: 'wiadomość' },
  // {id:'checkbox-policy',type:'checkbox'}
]

const Form = styled.form`
  width: 100%;
  max-width: 350px;
  display: grid;
  /* justify-content: center; */
  justify-items: center;
  align-items: center;
  align-content: center;
  row-gap: 50px;
  margin-top: 30px;
  position: relative;
  background-color: ${({ theme }) => theme.colorBlack};
  padding: 40px 20px;
  border-radius: 10px;

  @media (min-width: 768px) {
    margin: 30px auto 0;
  }
  @media (min-width: 1400px) {
    max-width: 100%;
    width: 100%;
  }
`
const Input = styled.input`
  border-bottom: 3px solid ${({ theme }) => theme.colorYellow} !important;
  background-color: ${({ theme }) => theme.colorBlack};

  /* border: 2px solid black !important; */
  /* border-color: ${props =>
    !props.isBad ? props.theme.colorYellow : props.theme.colorGrey}; */
  padding: 5px 0;
  margin-right: ${props => (props.checkbox ? '10px' : 0)};
  /* color: ${({ theme }) => theme.colorGrey}; */
  color: ${({ theme }) => theme.colorWhite};
  width: ${props => (props.checkbox ? 'auto' : '100%')};
`
const Label = styled.label`
  width: 100%;
  margin-bottom: ${props => (props.checkbox ? '0' : '8px')};
  color: ${props => props.theme[props.color]};
`
const ErrorText = styled(Desc)`
  color: ${({ theme }) => theme.colorRed};
  width: 100%;
  margin-top: 5px;
  opacity: ${props => (props.hasError ? 1 : 0)};
  transition: 0.3s;
`

const Wrap = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colorBlack};

  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  flex-grow: 1;
  /* margin: 30px 0; */
  &.checkbox {
    flex-direction: row;
    flex-wrap: wrap;
  }
`
const CheckboxInputWrap = styled.div`
  display: flex;
  align-items: center;
`

const StyledHeader = styled(Header)`
  margin-left: 10px;
  font-size: 15px;
`

const PolicyLink = styled.a`
  border-bottom: 1px solid ${({ theme }) => theme.colorWhite};
  &:hover {
    opacity: 0.8;
    color: ${({ theme }) => theme.colorWhite};
  }
`

const FormContact = props => {
  const formik = useFormik({
    initialValues: {
      fullname: '',
      email: '',
      message: '',
      checkboxPolicy: false,
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .min(5, 'Minimalnie 5 znaków!')
        .max(60, 'Za dużo znaków!')
        .required('Pole obowiązkowe!'),
      email: Yup.string()
        .email('Niepoprawny adres e-mail!')
        .max(60, 'Za dużo znaków!')
        .required('Pole obowiązkowe!'),
      message: Yup.string()
        .min(5, 'Minimalnie 5 znaków!')
        .max(350, 'Za dużo znaków!')
        .required('Pole obowiązkowe!'),
      // checkboxPolicy: Yup.boolean().required('Proszę zaakceptować'),
      checkboxPolicy: Yup.bool().oneOf([true], 'Proszę zatwierdzić'),
    }),
    onSubmit: (values, { resetForm }) => {
      alert(JSON.stringify(values, null, 2))
      //   formik.values = {}
      resetForm({})
    },
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      {data.map(obj => (
        <Wrap>
          <Label htmlFor={obj.id}>
            <StyledHeader as="h3" yellowRect align="left" color={'colorWhite'}>
              <ReactMarkdown
                disallowedTypes={['paragraph']}
                unwrapDisallowed
                children={obj.content}
              />
            </StyledHeader>
          </Label>
          <Input
            id={obj.id}
            as={obj.as || 'input'}
            name={obj.id}
            type={obj.type}
            onChange={formik.handleChange}
            value={formik.values[obj.id]}
            isBad={formik.errors[obj.id] && formik.touched[obj.id]}
            rows={obj.as && 3}
          />
          <ErrorText
            align="left"
            bold
            hasError={formik.touched[obj.id] && formik.errors[obj.id]}
          >
            {formik.touched[obj.id] && formik.errors[obj.id]
              ? formik.errors[obj.id]
              : 't'}
          </ErrorText>
        </Wrap>
      ))}

      <Wrap className="checkbox">
        <CheckboxInputWrap>
          <Input
            checkbox
            type="checkbox"
            id="checkboxPolicy"
            onChange={formik.handleChange}
            value={formik.values['checkboxPolicy']}
          />
          <Label checkbox color="colorWhite" htmlFor="checkboxPolicy">
            Zapoznałem/am się z {/* td dac to na nowa strone */}
            {/* <Link border to="/polityka-prywatnosci">
              polityką prywatności
            </Link> */}
            <PolicyLink href="/polityka-prywatnosci" target="_blank">
              polityką prywatności
            </PolicyLink>
          </Label>
        </CheckboxInputWrap>
        <ErrorText
          align="left"
          bold
          hasError={
            formik.touched['checkboxPolicy'] && formik.errors['checkboxPolicy']
          }
        >
          {formik.touched['checkboxPolicy'] && formik.errors['checkboxPolicy']
            ? formik.errors['checkboxPolicy']
            : 't'}
        </ErrorText>
      </Wrap>
      <Button type="submit">wyślij!</Button>
    </Form>
  )
}

export default FormContact
