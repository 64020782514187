import React, { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'

import styled from 'styled-components'
import Layout from '../layout/layout'
import Header from '../components/header/header'
import Desc from '../components/desc/desc'
import Banner from '../partials/banner/banner'
import axios from '../utils/axios'
import Loader from '../partials/loader/loader'

import DataSection from '../sections/contact/dataSection/dataSection.js'
import Form from '../sections/contact/form/form'
import FacebookWidget from '../components/facebookWidget/facebookWidget'

import ContentWrap from '../components/contentWrap/contentWrap'

const DataWrap = styled(ContentWrap)`
  &.form {
    display: grid;
    row-gap: 20px;
  }
  @media (min-width: 1400px) {
    grid-area: ${props => props.area};
    /* max-width: 400px; */
  }
`

const Map = styled.iframe`
  width: 100%;
  height: 30vh;
  border: 2px solid ${({ theme }) => theme.colorYellow};
  border-radius: 2px;
  /* border-right-color: ${({ theme }) => theme.colorYellow}; */
  margin-top: 20px;
  box-shadow: 0px 0px 0px 4px ${({ theme }) => theme.colorBlack};

  @media (min-width: 960px) {
    min-height: 360px;
  }
`
const StyledHeader = styled(Header)`
  font-size: 24px;
  @media (min-width: 1400px) {
    font-size: 30px;
  }
`
const FormHeader = styled(StyledHeader)`
  @media (min-width: 768px) {
    /* text-align: center; */
    text-align: left;
  }

  @media (min-width: 1400px) {
    text-align: left;
  }
`
const FormDesc = styled(Desc)`
  text-align: left;
  @media (min-width: 768px) {
    text-align: center;
    /* text-align: left; */
  }
  @media (min-width: 1400px) {
    text-align: left;
  }
`

const ContactFullWrap = styled(ContentWrap)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0;

  @media (min-width: 1400px) {
    /* flex-direction: row; */
    max-width: 1200px;
    display: grid;
    justify-items: center;
    align-items: flex-start;
    grid-template-areas: 'form data';
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 160px;
  }
`

const Contact = () => {
  const [respData, setRespData] = useState({})
  const [isDataFetched, setIsDataFetched] = useState(false)

  useEffect(() => {
    const fc = async () => {
      const response = await axios({
        data: JSON.stringify({
          query: `{
          contact{
            imgHeader{
              url
            }
            desc
            adres
            email
            phone
            code
            pnSat
            sun
            headForm
            headData
          }
        }`,
        }),
      })

      // console.log(response.data.data)
      setRespData(response.data.data)
      setIsDataFetched(true)
    }
    fc()
  }, [])

  return (
    <>
      {<Loader isDataLoaded={isDataFetched} />}

      <Layout title="Kontakt">
        {isDataFetched && (
          <>
            <Banner
              // area="banner"
              img={respData.contact.imgHeader.url}
            >
              kontakt
            </Banner>
            <ContactFullWrap>
              <DataWrap as={'main'} area="data">
                <StyledHeader h2 firstLetter align="left" yellowRect>
                  <ReactMarkdown
                    // disallowedTypes={['paragraph']}
                    // unwrapDisallowed
                    children={respData.contact.headData}
                  />
                </StyledHeader>
                <DataSection data={respData.contact} />

                <Map
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2394.0140487866265!2d18.010455215830184!3d53.12789337993383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470313bff8f2c711%3A0x1d3ff2babb245e62!2sStanis%C5%82awa%20Staszica%204%2C%20Bydgoszcz!5e0!3m2!1spl!2spl!4v1612810556322!5m2!1spl!2spl"
                  frameborder="0"
                  allowfullscreen=""
                  tabindex="0"
                />
              </DataWrap>

              <DataWrap as="section" className="form" area="form">
                <FormHeader h2 firstLetter yellowRect align="left">
                  <ReactMarkdown
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                    children={respData.contact.headForm}
                  />
                </FormHeader>
                <FormDesc>
                  {/* <ReactMarkdown
                    disallowedTypes={['paragraph']}
                    unwrapDisallowed
                    children={respData.contact.desc}
                  /> */}
                  <div
                    dangerouslySetInnerHTML={{ __html: respData.contact.desc }}
                    className="cms-text"
                  />
                </FormDesc>
                <Form />
              </DataWrap>
            </ContactFullWrap>
            <FacebookWidget />
          </>
        )}
      </Layout>
    </>
  )
}

export default Contact
