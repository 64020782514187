import React from 'react'
import styled from 'styled-components'
import SectionHeader from '../../components/sectionHeader/sectionHeader'

const Wrap = styled.header`
  width: 100%;
  position: relative;
  height: 25vh;
  min-height: 160px;
  /* min-height: 80px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url(${props => props.img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-color: ${({ theme }) => theme.colorBlack};
    opacity: 0.5;
  }
  @media (min-width: 960px) {
    min-height: 260px;
    margin-bottom: 20px;
  }
  @media (min-width: 1200px) {
    margin-bottom: 40px;
  }
  /* @media (min-width: 1400px) {
    grid-area: ${props => props.area || null};
  } */
  @media (min-width: 1400px) {
    min-height: 320px;
    margin-bottom: 80px;
  }
`

const StyledSectionHeader = styled(SectionHeader)`
  margin-top: 37px;
`

const Banner = props => {
  return (
    <Wrap area={props.area} img={props.img}>
      <StyledSectionHeader firstLetter>{props.children}</StyledSectionHeader>
    </Wrap>
  )
}

export default Banner
