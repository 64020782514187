import React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import fbIcon from '../../images/contact/fb.svg'

const Wrap = styled.aside`
  /* width: 400px; */
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: right;
  transform: ${props =>
    !props.isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: 0.3s;

  /* width: 300px; */
  /* min-height: 400px; */
  position: fixed;
  top: calc(30% + 40px);
  right: -85%;
  z-index: 188;
  cursor: pointer;
`
const BlueRect = styled.div`
  width: 150px;
  height: 40px;
  position: fixed;
  right: -50px;
  top: 30%;
  &:hover {
    cursor: pointer;
  }

  @media (min-width: 1024px) {
    width: 200px;
  }
  @media (min-width: 1200px) {
    /* width: 100%; */
  }

  margin-left: auto;
  margin-right: 50px;
  transform: ${props => (props.isOpen ? 'translateX(0)' : 'translateX(50px)')};
  transition: 0.3s;
  background-color: #3b5998;
  border-radius: 4px 0 0 4px;
  display: flex;
  align-items: center;
`

const StyledFb = styled.div`
  /* background-color: blue; */
  /* transform: ${props =>
    props.isOpen ? 'translateX(0)' : 'translateX(100%)'}; */
  /* width: 10px; */
  /* min-height: 400px; */

  transition: 0.3s;
`

const Img = styled.img`
  padding-left: 20px;
`

const FacebookWidget = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <BlueRect isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
        <Img src={fbIcon} alt={'Ikona facebook'} />
      </BlueRect>

      <Wrap isOpen={isOpen}>
        <StyledFb isOpen={isOpen}>
          <div
            class="fb-page"
            data-tabs="timeline,messages"
            data-href="https://www.facebook.com/szkolaszostka"

            //   data-hide-cover="false"
          ></div>
        </StyledFb>
      </Wrap>
    </>
  )
}

export default FacebookWidget
